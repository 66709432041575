<template lang="">
  <div class="priority__select-wrapper">
    <multiselect
      ref="multiselect"
      :value="value"
      :options="priorityLevelOptions"
      @select="handlePriorityLevel"
      :searchable="false"
      :close-on-select="true"
      :show-labels="false"
      v-b-tooltip.hover="customTooltip"
      class="priority__select"
    >
      <template slot="placeholder">
        <div class="priority__placeholder">
          <div class="priority__icon">
            <PriorityNormalIcon />
          </div>
        </div>
      </template>
      <template slot="option" slot-scope="props">
        <div class="priority__options">
          <div :class="['priority__option']">
            {{ getLabelPriority(props.option) }}
          </div>
        </div>
      </template>

      <template slot="singleLabel" slot-scope="{ option }">
        <div class="priority__singlelabel">
          <div class="priority__icon">
            <component :is="getIconPriority(option)" />
          </div>
          <div
            :class="['priority__singlelabel-name', getClassPriority(option)]"
          >
            {{ getLabelPriority(option) }}
          </div>
        </div>
      </template>
      <template slot="tag" slot-scope="{ option }">
        <div class="priority__singlelabel">
          <div class="priority__icon">
            <component :is="option" />
          </div>
          <div :class="['priority__singlelabel-name', option]">
            {{ option }}
          </div>
        </div>
      </template>
    </multiselect>
    <div
      class="priority__close-icon"
      @click="() => setPriorityLevelDefault(null)"
      v-if="value"
    >
      <CloseIcon fill="#A4B1DF" />
    </div>
  </div>
</template>
<script>
import PriorityNormalIcon from '@/assets/icons/priority-normal.svg'
import PriorityLowIcon from '@/assets/icons/priority-low.svg'
import PriorityMediumIcon from '@/assets/icons/priority-medium.svg'
import PriorityHighIcon from '@/assets/icons/priority-high.svg'
import PriorityVeryHighIcon from '@/assets/icons/priority-very-high.svg'
import CloseIcon from '@/assets/icons/close.svg'
import {
  getLabelPriority,
  getIconPriority,
  getClassPriority
} from '@/utils/priorityLevelHelper'

export default {
  name: 'PriorityLevelSelect',
  components: {
    PriorityNormalIcon,
    PriorityLowIcon,
    PriorityMediumIcon,
    PriorityHighIcon,
    PriorityVeryHighIcon,
    CloseIcon
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: String
  },
  computed: {
    customTooltip() {
      return (
        !this.value && {
          customClass: 'priority__select-tooltip-class',
          title: 'Defina a prioridade'
        }
      )
    }
  },
  data() {
    return {
      priorityLevelOptions: ['LOW', 'MEDIUM', 'HIGH', 'VERY_HIGH']
    }
  },
  methods: {
    handlePriorityLevel(priorityLevel) {
      this.$emit('input', priorityLevel)
      this.$emit('select', priorityLevel)
    },
    setPriorityLevelDefault(priorityLevel) {
      this.$emit('input', priorityLevel)
      this.$emit('select', priorityLevel)
      this.$refs.multiselect.isOpen = false
    },
    getLabelPriority(props) {
      return getLabelPriority(props)
    },
    getIconPriority(props) {
      return getIconPriority(props)
    },
    getClassPriority(props) {
      return getClassPriority(props)
    }
  }
}
</script>
<style lang="scss">
.priority__select-tooltip-class {
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #ebebf2 !important;
  gap: 10px !important;
  background-color: #ffffff !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08) !important;
  .arrow {
    display: none;
  }
  .tooltip-inner {
    background-color: #ffffff !important;
    color: #4a4a4a !important;
  }
}
.priority__select-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  .priority__icon {
    width: 20px;
    height: 20px;
  }

  .priority__select {
    position: relative;
    .multiselect__content-wrapper {
      min-width: 180px;
    }

    .multiselect__tags,
    .multiselect__single,
    .multiselect__placeholder {
      padding: 0 !important;
    }

    .multiselect__placeholder {
      display: flex !important;
    }

    .priority__placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 12px;
      background-color: #f4f5fb;
      border-radius: 100px;
      .priority__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
      }
    }

    .priority__singlelabel {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex: 1;
      align-items: center;
      border-radius: 100px;
      background-color: #f4f5fb;
      padding: 8px 12px;
      padding-right: 30px;

      .priority__singlelabel-name {
        color: #4a4a4a;
        &.normal {
          color: #4a4a4a;
        }
        &.low {
          color: #305bf2;
        }
        &.medium {
          color: #00c773;
        }
        &.high {
          color: #ffcc18;
        }
        &.very-high {
          color: #f63220;
        }

        .priority__icon {
          margin-right: 6px;
        }
      }
    }

    .priority__options {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;

      .priority__option {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        cursor: pointer;
      }
    }
  }
  .priority__close-icon {
    display: flex;
    align-items: center;
    width: 18px;
    height: 18px;
    position: relative;
    right: 25px;
  }
}
</style>
